import { FacebookOutlined, InstagramOutlined, YoutubeOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import styled from 'styled-components'

const Footer = () => {
  return (
    <>
      <MainContent>
        <Content style={{ display:'flex', flexDirection: 'column', alignItems:'flex-start', gap:10}}>
          <div style={{paddingInline: 15, fontSize: 25}}>SÍGUENOS EN <br/><b>NUESTRAS REDES</b></div>
       <div style={{ paddingInline: 15, marginTop: 5, display: 'flex', gap: 15, justifyContent: 'flex-start' }}>
        <Button style={{background: '#1b2241'}} size='large' shape='circle' type='primary' icon={<FacebookOutlined style={{color: '#fff', fontSize: 20}} />} target='_blank' href='https://www.facebook.com/canoescartagena' />
        <Button style={{background: '#1b2241'}} size='large' shape='circle' type='primary' icon={<InstagramOutlined style={{ color: '#fff', fontSize: 20 }} />} target='_blank' href='https://www.instagram.com/canoescartagena' />
        <Button style={{background: '#1b2241'}} size='large' shape='circle' type='primary' icon={ <YoutubeOutlined  style={{color: '#fff', fontSize: 20}}/>}target='_blank' href='https://www.youtube.com/@CanoesCaminoalMar' />
        </div>
        </Content>
        </MainContent>
      <Container>
       
        <Content>
          <span>Construye & Gerencia</span>
          <ImgContainer>
            <ImgContent>
              <img
                src={`${process.env.PUBLIC_URL}/images/footer/logo-delta-ingenieria.png`}
                alt="Logo Delta Ingeniería"
              />
            </ImgContent>
            <ImgContent>
              <img
                src={`${process.env.PUBLIC_URL}/images/footer/logo-escosa.png`}
                alt="Logo Escosa"
              />
            </ImgContent>
          </ImgContainer>
          <span>Arquitecto</span>
          <ImgContent>
            <img
              src={`${process.env.PUBLIC_URL}/images/footer/de-la-vega.png`}
              alt="Logo Arquitecto De La Vega"
            />
          </ImgContent>
          <span>Fiduciaria</span>
          <ImgContent>
            <img
              src={`${process.env.PUBLIC_URL}/images/footer/alianza-fiduciaria-logo.png`}
              alt="Logo Fiduciaria Alianza"
            />
          </ImgContent>
        </Content>
      </Container>
      <div>
        <Section style={{ fontSize: '0.8rem' }}>
          Las imágenes publicadas son representaciones digitales del diseño y
          junto con los inmuebles exhibidos, pueden variar en su percepción y
          construcción final. Los datos aquí publicados pueden variar sin previo
          aviso. Antes de tomar su decisión de compra por favor infórmese en la
          sala de negocios y con su asesor comercial acerca de las condiciones y
          características del proyecto.{' '}
        </Section>
      </div>
    </>
  )
}

export default Footer

const ImgContainer = styled.div`
  display: flex;
  gap: 20px;
  /* Styles for small screens (up to 768px) */
  @media screen and (max-width: 426px) {
    flex-direction: column;
  }
`

const ImgContent = styled.div`
  img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 3/1;
    max-width: 150px;
  }
`

const Section = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  padding: 20px;
`

const Content = styled(Section)`
  font-size: 1rem;
  display: grid;
  grid-template-columns: auto 2fr auto 1fr auto 1fr auto 1fr;
  grid-template-rows: auto;
  align-items: center;
  gap: 20px;

  /* Styles for small screens (up to 768px) */
  @media screen and (max-width: 1024px) {
    grid-template-columns: auto 2fr auto 1fr;
  }

  /* Styles for small screens (up to 768px) */
  @media screen and (max-width: 768px) {
    grid-template-columns: auto 1fr;
  }

  /* Styles for small screens (up to 768px) */
  @media screen and (max-width: 426px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`

const MainContent = styled.div`
margin-top: 100px;
 position: relative;
  &:after {
    content: '';
    width: 1px;
    height: 80px;
    position: absolute;
    top: -90px;
    left: 50%;
    background-color: #00000056;
  }
`
const Container = styled.div`
  padding: 20px 0;
  margin: 0 20px;
  background-color: #d3dbdf;
  position: relative;
  
`
