import { useEffect, useRef, useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Footer from './footer'
import NavMenu from './nav-menu'

import { MenuOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useAnimation } from 'framer-motion'
import { Helmet } from 'react-helmet-async'

const Layout = () => {
  const [scrolling, setScrolling] = useState(false)
  const controls = useAnimation()
  const [open, setOpen] = useState(false)

  const location = useLocation()

  const navRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'page_view',
      page_path: location.pathname,
      page_title: document.title,
      page_location: window.location.href,
    })
  }, [location.pathname])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    if (navRef.current && navRef.current.style) {
      if (
        location.pathname.includes('360') ||
        location.pathname.includes('video') ||
        location.pathname.includes('noticias')
      ) {
        setScrolling(true)
        navRef.current.style.backgroundColor = '#1b2241d2'
        document.documentElement.classList.add('nav-color')
        navRef.current.style.top = '0px'
        return
      }

      document.documentElement.classList.remove('nav-color')
      navRef.current.style.backgroundColor = 'transparent'
      navRef.current.style.top = '20px'
    }
  }, [location.pathname, navRef.current])

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current && navRef.current.style) {
        if (
          location.pathname.includes('360') ||
          location.pathname.includes('video') ||
          location.pathname.includes('noticias')
        ) {
          setScrolling(true)
          navRef.current.style.backgroundColor = '#1b2241d2'
          document.documentElement.classList.add('nav-color')
          navRef.current.style.top = '0px'
          return
        }

        if (window.scrollY < 20) {
          navRef.current.style.top = 20 - window.scrollY + 'px'
        } else {
          navRef.current.style.top = '0px'
        }

        if (window.scrollY > 20) {
          if (!scrolling) {
            setScrolling(true)
            navRef.current.style.backgroundColor = '#1b2241d2'
            document.documentElement.classList.add('nav-color')
          }
        } else {
          if (scrolling) {
            setScrolling(false)
            navRef.current.style.backgroundColor = 'transparent'
            document.documentElement.classList.remove('nav-color')
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrolling, controls, navRef.current, location.pathname])

  return (
    <>
      <Helmet>
        <title>Canoës - Un oasis, junto al mar</title>
        <meta
          name="description"
          content="En un rincón paradisíaco donde la brisa marina acaricia la piel y el sol pinta cada día con tonalidades cálidas, surge Canoës."
        />
      </Helmet>
      <Container>
        <Header ref={navRef}>
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/images/logo-canoes-cartagena.png`}
              style={{ height: 50 }}
              alt="Logo de Canoës - Casas en Cartagena de Indias"
            />
          </Link>
          <div style={{ flex: '1 auto' }} />
          <ButtonMenu
            onClick={() => void setOpen(true)}
            size="large"
            type="text"
            icon={<MenuOutlined style={{ color: '#fff' }} />}
          />
          <NavMenu open={open} onClose={() => void setOpen(false)} />
        </Header>
        <Outlet />
      </Container>
      {location.pathname !== '/' && <Footer />}
      <ButtonWhatsapp target="_blank" href="https://wa.me/3175955706">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          height="35px"
          width="35px"
          version="1.1"
          id="Layer_1"
          viewBox="0 0 308 308"
        >
          <g id="XMLID_468_">
            <path
              id="XMLID_469_"
              d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156   c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687   c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887   c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153   c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348   c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802   c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922   c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0   c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458   C233.168,179.508,230.845,178.393,227.904,176.981z"
            />
            <path
              id="XMLID_470_"
              d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716   c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396   c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z    M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188   l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677   c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867   C276.546,215.678,222.799,268.994,156.734,268.994z"
            />
          </g>
        </svg>
      </ButtonWhatsapp>
    </>
  )
}
export default Layout

const ButtonMenu = styled(Button)`
  display: none;
  @media (width <= 1029px) {
    display: initial;
  }
`

const ButtonWhatsapp = styled.a`
  background-color: #25d366;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.1s;
  &:hover {
    transform: scale(1.2);
  }
`

const Header = styled.div`
  position: fixed;
  width: calc(100% - 40px);
  max-width: calc(100dvw - 40px);
  left: 0;
  top: 20px;
  z-index: 2;
  display: flex;
  padding: 0 20px;
  align-items: center;
  transition: background-color 0.3s;
`

const Container = styled.div`
  width: calc(100% - 40px);
  max-width: 100dvw;
  padding: 20px;
`
