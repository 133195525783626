import { Navigate, Outlet, useRoutes } from 'react-router-dom'

import React from 'react'
import Layout from './layout'
const Home = React.lazy(() => import('../pages/home'))
const Mapa = React.lazy(() => import('../pages/ubicacion/mapa'))
const AltosGuayacanes = React.lazy(
  () => import('../pages/ubicacion/altos-guayacanes'),
)
const CanoesCaminoMar = React.lazy(
  () => import('../pages/proyecto/canoes-camino-mar'),
)
const MasterPlan = React.lazy(() => import('../pages/proyecto/master-plan'))
const ProyectoGaleria = React.lazy(() => import('../pages/proyecto/galeria'))
const ProyectoVideo = React.lazy(() => import('../pages/proyecto/video'))
const Descripcion = React.lazy(() => import('../pages/casa/descripcion'))
const Planos = React.lazy(() => import('../pages/casa/planos'))
const CasaGaleria = React.lazy(() => import('../pages/casa/galeria'))
const Recorrido360 = React.lazy(() => import('../pages/casa/recorrido-360'))
const Contactanos = React.lazy(() => import('../pages/contactenos'))
const Gracias = React.lazy(() => import('../pages/contactenos/gracias'))
const Noticias = React.lazy(() => import('../pages/noticias'))
const DesarrolladoresAliados = React.lazy(
  () => import('../pages/desarrolladores-aliados'),
)

const AppRoute = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '',
          element: <Home />,
        },
        {
          path: 'ubicacion',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <Navigate to="mapa" />,
            },
            {
              path: 'mapa',
              element: <Mapa />,
            },
            {
              path: 'altos-guayacanes',
              element: <AltosGuayacanes />,
            },
          ],
        },
        {
          path: 'proyecto',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <Navigate to="canoes-camino-mar" />,
            },
            {
              path: 'canoes-camino-mar',
              element: <CanoesCaminoMar />,
            },
            {
              path: 'master-plan',
              element: <MasterPlan />,
            },
            {
              path: 'galeria',
              element: <ProyectoGaleria />,
            },
            {
              path: 'video',
              element: <ProyectoVideo />,
            },
          ],
        },
        {
          path: 'casas',
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <Navigate to="descripcion" />,
            },
            {
              path: 'descripcion',
              element: <Descripcion />,
            },
            {
              path: 'planos',
              element: <Planos />,
            },
            {
              path: 'galeria',
              element: <CasaGaleria />,
            },
            {
              path: '360',
              element: <Recorrido360 />,
            },
          ],
        },
        {
          path: 'desarrolladores-aliados',
          element: <DesarrolladoresAliados />,
        },
        {
          path: 'contactanos',
          element: <Contactanos />,
        },
        {
          path: 'noticias',
          element: <Noticias />,
        },
        {
          path: 'gracias',
          element: <Gracias />,
        },
      ],
    },
  ])

  return routes
}

export default AppRoute
