import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import AppRoute from './components/app-route'

const App = () => {
  return (
    <HelmetProvider>
      <React.Suspense>
        <AppRoute />
      </React.Suspense>
    </HelmetProvider>
  )
}

export default App
